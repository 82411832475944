/**
 * @license
 * Copyright 2019 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

// Former goog.module ID: Blockly.blockRendering.IPathObject

import type {BlockStyle} from '../../theme.js';
import type {BlockSvg} from '../../block_svg.js';
import type {ConstantProvider} from './constants.js';
import type {RenderedConnection} from '../../rendered_connection.js';
import type {Coordinate} from '../../utils/coordinate.js';

/**
 * An interface for a block's path object.
 *
 * @param _root The root SVG element.
 * @param _constants The renderer's constants.
 */
export interface IPathObject {
  /**
   * The primary path of the block.
   */
  svgPath: SVGElement;

  /** The renderer's constant provider. */
  constants: ConstantProvider;

  /** The primary path of the block. */
  style: BlockStyle;

  /**
   * Holds the cursors SVG element when the cursor is attached to the block.
   * This is null if there is no cursor on the block.
   */
  cursorSvg: SVGElement | null;

  /**
   * Holds the markers SVG element when the marker is attached to the block.
   * This is null if there is no marker on the block.
   */
  markerSvg: SVGElement | null;

  /**
   * Set the path generated by the renderer onto the respective SVG element.
   *
   * @param pathString The path.
   */
  setPath(pathString: string): void;

  /**
   * Apply the stored colours to the block's path, taking into account whether
   * the paths belong to a shadow block.
   *
   * @param block The source block.
   */
  applyColour(block: BlockSvg): void;

  /**
   * Update the style.
   *
   * @param blockStyle The block style to use.
   */
  setStyle(blockStyle: BlockStyle): void;

  /**
   * Flip the SVG paths in RTL.
   */
  flipRTL(): void;

  /**
   * Add the cursor SVG to this block's SVG group.
   *
   * @param cursorSvg The SVG root of the cursor to be added to the block SVG
   *     group.
   */
  setCursorSvg(cursorSvg: SVGElement): void;

  /**
   * Add the marker SVG to this block's SVG group.
   *
   * @param markerSvg The SVG root of the marker to be added to the block SVG
   *     group.
   */
  setMarkerSvg(markerSvg: SVGElement): void;

  /**
   * Set whether the block shows a highlight or not.  Block highlighting is
   * often used to visually mark blocks currently being executed.
   *
   * @param highlighted True if highlighted.
   */
  updateHighlighted(highlighted: boolean): void;

  /**
   * Add or remove styling showing that a block is selected.
   *
   * @param enable True if selection is enabled, false otherwise.
   */
  updateSelected(enabled: boolean): void;

  /**
   * Add or remove styling showing that a block is dragged over a delete area.
   *
   * @param enable True if the block is being dragged over a delete area, false
   *     otherwise.
   */
  updateDraggingDelete(enabled: boolean): void;

  /**
   * Add or remove styling showing that a block is an insertion marker.
   *
   * @param enable True if the block is an insertion marker, false otherwise.
   */
  updateInsertionMarker(enabled: boolean): void;

  /**
   * Add or remove styling showing that a block is movable.
   *
   * @param enable True if the block is movable, false otherwise.
   */
  updateMovable(enabled: boolean): void;

  /** Adds the given path as a connection highlight for the given connection. */
  addConnectionHighlight?(
    connection: RenderedConnection,
    connectionPath: string,
    offset: Coordinate,
    rtl: boolean,
  ): void;

  /**
   * Removes any highlight associated with the given connection, if it exists.
   */
  removeConnectionHighlight?(connection: RenderedConnection): void;
}
